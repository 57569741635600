import { useRouter } from 'next/router';
import { useTheme } from 'styled-components';

import { Box, Grid, IconProps, Typography } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';
import { checkLinkSupportLocale } from '@shared/NavMenu/helpers';

import { MenuItem } from '../../types';

import { Link } from '../styledComponents';

type MenuItemLevelProps = {
  data: MenuItem;
};

type AccordionProps = {
  title: string;
  icon?: IconProps['icon'];
};

const AccordionItem = ({ title, icon }: AccordionProps) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 'medium' }}>
    <Box
      sx={{
        width: 36,
        height: 36,
        minWidth: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgColor: 'defaultBackground',
        borderRadius: 'medium',
      }}
    >
      <Image
        src={`/assets/menu-icons/${icon}.svg`}
        sx={{ width: 24, height: 24 }}
        data-test-id={icon}
      />
    </Box>

    <Typography.Text fontSize={18} fontWeight="semi-bold">
      {title}
    </Typography.Text>
  </Box>
);

const AccordionDesktop = ({ data }: MenuItemLevelProps) => {
  const router = useRouter();

  return (
    <Grid.Col span={[12, 12, 12, 12, 12]}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 'medium',
          pb: 'large',
        }}
      >
        <Box
          sx={{
            width: 36,
            height: 36,
            minWidth: 36,
            display: 'flex',
            alignItems: 'center',
            borderRadius: 'medium',
            justifyContent: 'center',
            bgColor: 'defaultBackground',
          }}
        >
          <Image
            src={`/assets/menu-icons/${data.icon}.svg`}
            sx={{ width: 24, height: 24 }}
            data-test-id={data.icon}
          />
        </Box>

        <Typography.Text fontWeight="semi-bold" fontSize={16}>
          {data.title}
        </Typography.Text>
      </Box>

      <Box
        sx={{
          pl: 'small',
          gap: 'medium',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        {data.items?.map(
          item =>
            item.type !== 'hidden' &&
            checkLinkSupportLocale(item.locales, router.locale) && (
              <Typography.Text key={item.title}>
                <Link item={item}>{item.title}</Link>
              </Typography.Text>
            )
        )}
      </Box>
    </Grid.Col>
  );
};

const AccordionSmallScreens = ({ data }: MenuItemLevelProps) => {
  const theme = useTheme();
  const router = useRouter();

  return (
    <>
      <AccordionItem title={data.title} icon={data.icon} />

      <Box
        sx={{
          ml: 'medium',
          mb: 'large',
          pt: 'small',
          pb: 'small',
          pl: 'medium',
          pr: 'medium',
          gap: 'medium',
          display: 'flex',
          flexDirection: 'column',
          borderLeft: `1px solid ${theme.colors.defaultBorder}`,
        }}
      >
        {data.items?.map(
          item =>
            item.type !== 'hidden' &&
            checkLinkSupportLocale(item.locales, router.locale) && (
              <Typography.Text key={item.title}>
                <Link item={item}>{item.title}</Link>
              </Typography.Text>
            )
        )}
      </Box>
    </>
  );
};

export default {
  Desktop: AccordionDesktop,
  SmallScreens: AccordionSmallScreens,
};
