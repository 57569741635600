import isNull from 'lodash/fp/isNull';

import React, { useCallback, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import { Box, Button, Grid, theme, Typography } from '@hero-design/react';

import generateGetURL from '@packages/eh-utils/urlGenerator';
import { mediaMaxQueries, themeGet } from '@packages/hero-theme/utils';
import AppliedIndicator from '@shared/AppliedIndicator';
import SaveJobButton from '@shared/SaveJobButton';
import UnSigninSaveJobButton from '@shared/SaveJobButton/UnSigninSaveJobButton';
import useFetchPermissions from '@shared/hooks/useFetchPermissions';
import useWindowSize from '@shared/hooks/useWindowSize';
import { format } from '@shared/utils/formatDate';
import {
  TRACK_ROLE_CLICK,
  useMixpanelTracking,
} from '@shared/trackTools/mixpanel';

import useCreateOrGetMessageThread from 'src/modules/directMessages/hooks/useCreateOrGetMessageThread';

import { APPLICATIONS_TRACKING_TABS } from '../ApplicationsTracking';
import WithdrawApplicationModal from '../ApplicationsTracking/WithDrawModal';
import useFetchAllCountries from '../../hooks/useFetchAllCountries';
import {
  getJobAppliedAt,
  getJobCreatedAt,
  getRemoteSettingsDescription,
} from '../../utils/helpers';
import {
  AppliedJobItem,
  JobItem,
  SavedJobItem,
  SearchCareerPageJobItem,
} from '../../types';

import CompanyName from './CompanyName';
import DirectMessageModal from './DirectMessageModal';
import CompanyLogo from './RedesignRoleItem/CompanyLogo';

import genJobDetailPageLink from './common/genJobDetailPageLink';
import getCareerPageJobId from './common/getCareerPageJobId';
import { ALLOW_JOB_DETAIL_PAGES } from './constants';

const ItemWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  ${mediaMaxQueries.md} {
    align-items: flex-start;
  }
`;

const ContentWrapper = styled(Box)`
  flex: 1;
  margin-left: ${themeGet('space.medium')}px !important;
`;

const LinkWrapper = styled.a`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-decoration: none;
  z-index: 0;
`;

const Content = styled(Box)`
  ${mediaMaxQueries.md} {
    display: flex;
    flex-direction: column;
    p {
      margin: 0 !important;
    }
    p:not(:first-child) {
      margin-top: ${themeGet('space.xsmall')}px !important;
    }
  }
`;

type RoleItemProps = {
  data: JobItem | SearchCareerPageJobItem | SavedJobItem | AppliedJobItem;
  countryName: string;
  companyLogo?: string;
  companyName?: string;
  selectedTab?: string;
  setSelectedTab?: (value: string) => void;
  showStar: boolean;
  fetchSavedJobs?: () => void;
};

type RoleItemDetailProps = {
  data: JobItem | SearchCareerPageJobItem | SavedJobItem | AppliedJobItem;
  countryName: string;
  companyLogo?: string;
  companyName?: string;
  selectedTab?: string;
  setSelectedTab?: (value: string) => void;
  showStar: boolean;
  fetchSavedJobs?: () => void;
  onRoleClick?: () => void;
  hasJobDetailPage: boolean | null;
  href: string;
};

export const RoleItemDetail = ({
  data,
  countryName,
  companyLogo,
  companyName,
  selectedTab,
  setSelectedTab,
  showStar,
  fetchSavedJobs,
  onRoleClick,
  hasJobDetailPage,
  href,
}: RoleItemDetailProps) => {
  const [showDirectMessageModal, setShowDirectMessageModal] = useState(false);
  const { isSmallScreen } = useWindowSize();

  const router = useRouter();
  const [
    isConfirmWithdrawApplicationModal,
    setIsConfirmWithdrawApplicationModal,
  ] = useState(false);
  const isApplicationsPage = useMemo(
    () => router.pathname === '/user/applications',
    [router.pathname]
  );

  const { rawCountriesData } = useFetchAllCountries();

  const careerPageJobId = data?.id;
  const isSaved = data.is_saved;
  const lastAppliedAt = getJobAppliedAt(data);
  const workplaceType = 'workplace_type' in data ? data.workplace_type : '';
  const remoteSetting =
    'remote_setting' in data ? data.remote_setting : undefined;

  const isWithdrawAble = useMemo(
    () =>
      isApplicationsPage &&
      (selectedTab === APPLICATIONS_TRACKING_TABS.APPLIED ||
        selectedTab === APPLICATIONS_TRACKING_TABS.IN_PROGRESS),
    [isApplicationsPage, selectedTab]
  );

  const { permissionsData } = useFetchPermissions();

  const enabledDirectMessagePage =
    permissionsData?.data.direct_messaging_slice_2_enabled;

  // Show star: When user logged in and  when has a permission
  const showBookmarkButton =
    showStar && permissionsData?.data?.access_saved_jobs && !isNull(isSaved);

  const showUnsigninStarButton =
    showStar && permissionsData?.data?.access_saved_jobs && isNull(isSaved);

  const remoteSettingsDescription = useMemo(
    () =>
      getRemoteSettingsDescription({
        workplaceType: workplaceType || '',
        remoteSetting,
        rawCountriesData,
      }),
    [workplaceType, remoteSetting, rawCountriesData]
  );

  const {
    fetcher: createOrGetMessageThread,
    loading: creatingOrGettingMessageThread,
  } = useCreateOrGetMessageThread();

  const handleOpenDirectMessage = useCallback(async () => {
    if (enabledDirectMessagePage) {
      const threadData = await createOrGetMessageThread({
        message_threadable_id: data.id,
        message_threadable_type: 'CandidateJob',
      });

      router.push({
        pathname: '/messages',
        query: {
          thread_id: threadData.data.thread_document_id,
        },
      });
    } else {
      setShowDirectMessageModal(true);
    }
  }, [createOrGetMessageThread, data.id, enabledDirectMessagePage, router]);

  return (
    <Box
      mt="small"
      p="medium"
      bgColor="white"
      borderRadius="medium"
      sx={{
        display: 'flex',
        flexDirection: isSmallScreen ? 'column' : 'row',
      }}
    >
      <DirectMessageModal
        open={showDirectMessageModal}
        onClose={() => setShowDirectMessageModal(false)}
      />

      <Box sx={{ display: 'flex', flex: 1, position: 'relative' }}>
        <LinkWrapper
          href={hasJobDetailPage ? href : undefined}
          style={{ cursor: hasJobDetailPage ? 'pointer' : 'default' }}
          rel="canonical"
          onClick={onRoleClick}
        />

        <ItemWrapper>
          <CompanyLogo
            companyLogo={companyLogo}
            companyName={companyName}
            extraStyles={{
              backgroundColor: 'defaultGreyBackground',
              borderRadius: 'medium',
            }}
          />

          <ContentWrapper>
            <Content style={{ display: 'flex' }}>
              <Typography.Title
                level={5}
                sx={{
                  mb: 'small',
                  color: 'primary',
                  flex: 1,
                }}
              >
                {data.title}
              </Typography.Title>
            </Content>

            <Grid>
              <Grid.Row gutter={['medium', 'medium']}>
                <Grid.Col span={[24, 24, 19, 19, 19]}>
                  <Content>
                    <CompanyName data={data} companyName={companyName} />

                    <Typography.Text
                      intent="body"
                      sx={{ mr: 'small' }}
                      tagName="span"
                    >
                      •{' '}
                      {data.vendor_location_name
                        ? `${data.vendor_location_name}, `
                        : ''}
                      {countryName} •
                    </Typography.Text>

                    <Typography.Text intent="body" tagName="span">
                      {data.employment_type_name}
                    </Typography.Text>

                    <Typography.Text intent="body" tagName="span">
                      {remoteSettingsDescription}
                    </Typography.Text>
                  </Content>
                </Grid.Col>
              </Grid.Row>

              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography.Text intent="body">
                  posted {format('DD MMM YYYY')(getJobCreatedAt(data) || '')}
                </Typography.Text>
              </Box>
            </Grid>
          </ContentWrapper>
        </ItemWrapper>
      </Box>

      <Box
        sx={{
          mt: isSmallScreen ? 'medium' : undefined,
          display: 'flex',
          flexDirection: isSmallScreen ? 'row' : 'column',
          justifyContent: 'space-between',
          alignItems: 'right',
        }}
      >
        {lastAppliedAt ? (
          <Box>
            <AppliedIndicator appliedAt={lastAppliedAt} />
          </Box>
        ) : null}

        <Box />

        <Box sx={{ display: 'inline-flex', justifyContent: 'flex-end' }}>
          {showBookmarkButton && (
            <SaveJobButton
              isSaved={isSaved}
              careerPageJobId={careerPageJobId}
              fetchSavedJobs={fetchSavedJobs}
            />
          )}

          {showUnsigninStarButton && <UnSigninSaveJobButton />}

          {isWithdrawAble && (
            <>
              <Button.Icon
                icon={
                  creatingOrGettingMessageThread ? 'loading' : 'chat-outlined'
                }
                sx={{ alignItems: 'center' }}
                onClick={handleOpenDirectMessage}
                disabled={creatingOrGettingMessageThread}
                data-test-id="direct-message-button"
              />
              <Button.Icon
                icon="trash-bin-outlined"
                intent="danger"
                onClick={() => {
                  setIsConfirmWithdrawApplicationModal(true);
                }}
                style={{
                  marginLeft: theme.space.large,
                }}
              />

              {isConfirmWithdrawApplicationModal && (
                <WithdrawApplicationModal
                  id={data.id}
                  setIsConfirmWithdrawApplicationModal={
                    setIsConfirmWithdrawApplicationModal
                  }
                  setSelectedTab={setSelectedTab}
                />
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const RoleItem = ({
  data,
  countryName,
  companyLogo,
  companyName,
  selectedTab,
  setSelectedTab,
  showStar,
  fetchSavedJobs,
}: RoleItemProps) => {
  const router = useRouter();
  const { track } = useMixpanelTracking();

  const { permissionsData } = useFetchPermissions();
  const swagSeoStrategy2024Enabled =
    permissionsData?.data.swag_seo_strategy_2024_enabled || false;

  const careerPageJobId = useMemo(
    () => getCareerPageJobId(data, { swagSeoStrategy2024Enabled }),
    [swagSeoStrategy2024Enabled, data]
  );

  const onRoleClick = useCallback(() => {
    if (router?.pathname && router.pathname in TRACK_ROLE_CLICK) {
      track(TRACK_ROLE_CLICK[router.pathname]);
    }
  }, [track, router?.pathname]);

  const jobAdvertId = 'job_advert_id' in data ? data.job_advert_id : undefined;
  const sourceName = 'source_name' in data ? data.source_name : undefined;

  const href = useMemo(() => {
    const jobDetailLink = genJobDetailPageLink({
      isJobAdvert: !!jobAdvertId,
      jobAdvertId: jobAdvertId || '',
      careerPageJobId,
      locale: router?.locale,
    });

    return generateGetURL(jobDetailLink, {
      utm_medium: router?.query?.utm_medium,
    });
  }, [careerPageJobId, jobAdvertId, router?.locale, router?.query?.utm_medium]);

  const hasJobDetailPage = useMemo(
    () =>
      ALLOW_JOB_DETAIL_PAGES.includes(sourceName || '') ||
      router?.pathname !== '/user/applications' ||
      data.is_saved,
    [data.is_saved, sourceName, router?.pathname]
  );

  return (
    <RoleItemDetail
      data={data}
      countryName={countryName}
      companyLogo={companyLogo}
      companyName={companyName}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      showStar={showStar}
      fetchSavedJobs={fetchSavedJobs}
      onRoleClick={onRoleClick}
      hasJobDetailPage={hasJobDetailPage}
      href={href}
    />
  );
};

export default RoleItem;
