import React from 'react';

import { Box, Button, Modal, Typography } from '@hero-design/react';

import QRCode from '@shared/QRCode';

import ChatIcon from './ChatIcon';

const DirectMessageModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => (
  <Modal
    open={open}
    size="xlarge"
    canOutsideClickClose={false}
    variant="basic"
    onClose={onClose}
    title="Message the hiring manager"
    body={
      <>
        <Typography.Text fontWeight="semi-bold" sx={{ mb: 'small' }}>
          We currently don’t have messaging capability on web - yet. Download
          the Employment Hero app.
        </Typography.Text>
        <Typography.Text intent="subdued" sx={{ mb: 'medium' }}>
          Scan the QR code below on your mobile device to download the
          Employment Hero app. Once inside, go to your applications and press
          the <ChatIcon /> icon to message the hiring manager for this role.
        </Typography.Text>
        <Box sx={{ width: '100px', height: '100px' }}>
          <QRCode />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 'large',
          }}
        >
          <Button
            data-test-id="done-btn"
            size="large"
            text="Done"
            onClick={onClose}
          />
        </Box>
      </>
    }
  />
);

export default DirectMessageModal;
