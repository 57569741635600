import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';

import mixpanel from 'mixpanel-browser';
import 'nprogress/nprogress.css';
import NProgress from 'nprogress';

import { systemPalette } from '@hero-design/react';

import CustomSessionProvider from '@packages/custom-session-provider';
import AppcuesContainer from '@packages/eh-appcues/container';
import ErrorBoundary from '@packages/error-boundary';
import GoogleTagManagerContainer from '@packages/google-tag-manager/container';
import GlobalThemeProvider from '@packages/hero-theme/ThemeProvider';
import MarketoContainer from '@packages/marketo-form/container';
import MazeContainer from '@packages/maze/container';
import WebtrendsOptimizeTagContainer from '@packages/webtrends-optimize-tag/container';
import PageLayout from '@shared/PageLayout';
import useChangeViewPort from '@shared/hooks/useChangeViewPort';
import { PageComponent } from '@shared/types';

import { getMixPanelTokenId } from 'src/modules/CareersPage/utils/getFrontendEnv';
import SMSConsentModal from 'src/modules/User/components/ProfileSettings/SMSConsentModal';

import '@hero-design/react/es/styles/index.css';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/globals.css';
import '../../styles/marketo-request-demo-form.css';
import '../../styles/marketo-email-subscription.css';

mixpanel.init(`${getMixPanelTokenId()}`, {
  api_host: 'https://api.mixpanel.com',
  record_mask_text_selector: '',
});

const App = ({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps & {
  pageProps: any;
}): JSX.Element => {
  const ApplicationLayout = (Component as PageComponent).Layout || PageLayout;
  const viewportContent = useChangeViewPort();

  useEffect(() => {
    NProgress.configure({ showSpinner: false });

    Router.events.on('routeChangeStart', () => {
      NProgress.start();
    });

    Router.events.on('routeChangeComplete', () => {
      NProgress.done();
    });

    Router.events.on('routeChangeError', () => {
      NProgress.done();
    });
  }, []);

  return (
    <CustomSessionProvider
      session={session}
      refetchInterval={7 * 60}
      refetchOnWindowFocus
    >
      <Head>
        <title>Jobs by Employment Hero</title>
        <meta name="viewport" content={viewportContent} />
        <link rel="icon" href="/eh_favicon.png" />
      </Head>

      <WebtrendsOptimizeTagContainer />
      <GoogleTagManagerContainer />
      <AppcuesContainer />
      <MazeContainer />
      <MarketoContainer />
      <main>
        <GlobalThemeProvider>
          <ToastContainer
            position="top-right"
            autoClose={4000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            closeButton={false}
            limit={3}
          />

          <ErrorBoundary>
            <ApplicationLayout pageProps={pageProps}>
              <SMSConsentModal />
              <Component {...pageProps} />
            </ApplicationLayout>
          </ErrorBoundary>
        </GlobalThemeProvider>

        <style jsx global>{`
          #nprogress .bar {
            background: ${systemPalette.primary};
          }

          #nprogress .peg {
            box-shadow: 0 0 10px ${systemPalette.primary},
              0 0 5px ${systemPalette.primary};
          }

          #nprogress .spinner-icon {
            border-top-color: ${systemPalette.primary};
            border-left-color: ${systemPalette.primary};
          }
        `}</style>
      </main>
    </CustomSessionProvider>
  );
};

export default App;
