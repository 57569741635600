import React, { ChangeEvent } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
} from 'react-hook-form';

import { Input } from '@hero-design/react';
import { SxObject } from '@hero-design/react/types/components/common/ThemeInterfaces';

import Error from '../Error';
import FieldLabel from '../FieldLabel';
import InputContainer from '../InputContainer';
import { ExtraProps, LabelProps } from '../types';

interface TextAreaInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  inputProps?: React.ComponentProps<typeof Input.TextArea>;
  labelProps?: LabelProps;
  extraProps?: ExtraProps;
  sx?: SxObject;
}

const TextAreaInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  field,
  fieldState,
  inputProps = {},
  labelProps = {},
  extraProps = {},
  sx,
}: TextAreaInputProps<TFieldValues, TName>) => {
  const { placeholder, autoResize, disabled, autoComplete, maxLength } =
    inputProps;
  const { text: labelText, required, tooltip, inline = false } = labelProps;
  const { error } = fieldState;
  const { value, onChange, onBlur, name } = field;
  const {
    error: extraError,
    'data-test-id': dataTestId,
    errorStyle,
  } = extraProps;
  const hasError = error != null || extraError != null;
  const onInputChange = React.useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value),
    [onChange]
  );

  const id = `hero-theme-text-area-input__${name}`;

  return (
    <InputContainer data-test-id={dataTestId} inline={inline}>
      <FieldLabel
        required={required}
        text={labelText}
        hasError={hasError}
        disabled={disabled}
        tooltip={tooltip}
        clickable
        htmlFor={id}
        input={
          <Input.TextArea
            id={id}
            name={name}
            value={value}
            onChange={onInputChange}
            onBlur={onBlur}
            placeholder={placeholder}
            invalid={hasError}
            autoResize={autoResize}
            disabled={disabled}
            autoComplete={autoComplete}
            maxLength={maxLength}
            sx={sx}
          />
        }
      />

      {hasError && (
        <Error
          style={errorStyle}
          text={(error?.message as string) || (extraError as string)}
        />
      )}
    </InputContainer>
  );
};

export default TextAreaInput;
