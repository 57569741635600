import { GetServerSidePropsContext } from 'next';
import { NextRequest } from 'next/server';
import { getToken } from 'next-auth/jwt';

export type Method = 'GET' | 'PUT' | 'PATCH' | 'POST' | 'DELETE';

type ErrorObject = {
  reason: string;
  message: string;
  location?: string;
  location_type?: string;
};

export type InitFetchData<T> = {
  errorCode: boolean | number;
  status?: number;
  data?: T;
  error?: { errors: Array<ErrorObject> };
};

export const tryJSON = (raw: string) => {
  try {
    return JSON.parse(raw);
  } catch (ex) {
    return raw;
  }
};

const secret = process.env.NEXTAUTH_SECRET;

const fetchData = async <T, B = unknown>(
  req: GetServerSidePropsContext['req'] | NextRequest,
  url: string,
  headers?: Record<string, string>,
  method: Method = 'GET',
  body?: B // Add body parameter to support POST, PUT, PATCH
): Promise<InitFetchData<T>> => {
  try {
    const token = await getToken({ req, secret });

    const jwt = (token?.jwt as string) || '';

    const reqHeaders = {
      ...headers,
    };

    if (process.env.ATS_SECRET_TOKEN) {
      // eslint-disable-next-line immutable/no-mutation
      reqHeaders['X-ATS-TOKEN'] = process.env.ATS_SECRET_TOKEN;
    }

    if (jwt) {
      // eslint-disable-next-line immutable/no-mutation
      reqHeaders['Jwt-Token'] = jwt;
    }

    const fetchOptions: RequestInit = {
      method,
      headers: reqHeaders,
      body: method !== 'GET' && body ? JSON.stringify(body) : undefined,
    };

    const res = await fetch(url, fetchOptions);

    if (!res.ok) {
      // TODO: This error is flooding our Sentry, temporary stop sending this error until problem is resolved
      // Sentry.captureEvent({
      //   message: '[server side] fetch with error response',
      //   extra: {
      //     errorCode: res.status,
      //     error: JSON.stringify(json.error),
      //   },
      // });
      const err = tryJSON(await res.text());

      // eslint-disable-next-line no-console
      console.log(
        JSON.stringify({
          msg: 'fetchData-error-logger',
          code: res.status,
          headers: res.headers,
          url,
          resText: err || 'no res',
        })
      );

      return {
        status: res.status,
        errorCode: res.status,
        error: err.error,
      };
    }

    const contentType = res.headers.get('content-type');

    // For GET requests or responses with JSON content type => Parse as JSON, otherwise, read as text
    const data =
      method === 'GET' ||
      (contentType && contentType.includes('application/json'))
        ? await res.json()
        : await res.text();

    return {
      status: res.status,
      errorCode: false,
      data: data?.data,
    };
  } catch (err) {
    // TODO: This error is flooding our Sentry, temporary stop sending this error until problem is resolved
    // Sentry.captureEvent({
    //   message: '[server side] fetch with network error',
    //   extra: {
    //     err,
    //   },
    // });

    // eslint-disable-next-line no-console
    console.log(
      JSON.stringify({
        msg: 'fetchData-error-logger',
        headers: req.headers,
        error: err,
      })
    );

    return {
      errorCode: 500,
    };
  }
};

export default fetchData;
