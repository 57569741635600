import compact from 'lodash/fp/compact';
import upperCase from 'lodash/fp/upperCase';

import React, { HTMLAttributeAnchorTarget, useCallback } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import styled from 'styled-components';

import { Badge, Box, Typography } from '@hero-design/react';

import generateGetURL from '@packages/eh-utils/urlGenerator';
import { mediaMaxQueries, themeGet } from '@packages/hero-theme/utils';
import AuthStatus from '@shared/AuthStatus';
import LocalesDropdown from '@shared/LocalesDropdown';
import { Logo } from '@shared/Logo';
import useFetchPermissions from '@shared/hooks/useFetchPermissions';

import NotificationBell from './NotificationBell';
import SideBar from './SideBar';

interface NavWrapperProps {
  centerItem?: boolean;
}

const LinkWrapper = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: ${themeGet('space.small')}px;
`;

const NavWrapper = styled.div<NavWrapperProps>`
  background-color: ${({ theme }) => theme.colors.defaultLightBackground};
  padding: 0px 130px;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.centerItem ? 'center' : 'flex-start')};
  height: 86px;

  ${mediaMaxQueries.xl} {
    padding: 0px 60px;
  }
  ${mediaMaxQueries.lg} {
    .navbar-item:not(:first-of-type) {
      display: none;
    }
  }
  ${mediaMaxQueries.md} {
    padding: 0px 50px;
  }
  ${mediaMaxQueries.sm} {
    padding: 5px 30px;
  }
`;

type navBarItem = {
  id: string;
  name: string;
  href: string;
  onClick?: () => void;
  target?: HTMLAttributeAnchorTarget;
  showIndicator?: boolean;
};
interface NavBavProp {
  navBarItems: navBarItem[];
}

const NavBar: React.FC<NavBavProp> = ({ navBarItems }) => {
  const { permissionsData } = useFetchPermissions();

  const isShowNotificationBell =
    permissionsData?.data.direct_messaging_slice_2_enabled ||
    permissionsData?.data.notification_v1_enabled;

  const { query } = useRouter();
  const { status } = useSession();

  const isAuthenticated = status === 'authenticated';

  const normalizeHref = useCallback(
    (href: string) =>
      generateGetURL(href, {
        mode: query?.mode,
      }),
    [query?.mode]
  );

  const authStatusNotSignedInNavBarItems = compact([
    !isAuthenticated && {
      id: 'log-in',
      name: 'Log In',
      href: '/sign_in',
    },
    !isAuthenticated && {
      id: 'sign-up',
      name: 'Sign Up',
      href: '/setup_profile',
    },
  ]);

  const sideBarItems = [...authStatusNotSignedInNavBarItems, ...navBarItems];

  return (
    <>
      <NavWrapper>
        <SideBar navBarItems={sideBarItems} />
        <Link href={normalizeHref('/')} passHref legacyBehavior>
          <LinkWrapper>
            <Logo id="eh-logo" />
          </LinkWrapper>
        </Link>

        {navBarItems.map(
          ({ id, name, href, onClick, showIndicator, target = '_self' }) => (
            <Link href={normalizeHref(href)} passHref key={id} legacyBehavior>
              <LinkWrapper
                id={id}
                className="navbar-item"
                target={target}
                onClick={onClick}
              >
                {showIndicator ? (
                  <div data-test-id="red-dot-indicator-saved-candidate-wrapper">
                    <Badge.Status>
                      <Typography.Text
                        fontSize={14}
                        fontWeight="semi-bold"
                        sx={{ ml: 'xlarge' }}
                      >
                        {upperCase(name)}
                      </Typography.Text>
                    </Badge.Status>
                  </div>
                ) : (
                  <Typography.Text
                    fontSize={14}
                    fontWeight="semi-bold"
                    sx={{ ml: 'xlarge' }}
                  >
                    {upperCase(name)}
                  </Typography.Text>
                )}
              </LinkWrapper>
            </Link>
          )
        )}

        <Box
          sx={{ margin: '0 0 0 auto', display: 'flex', alignItems: 'center' }}
        >
          <LocalesDropdown />

          {isAuthenticated && isShowNotificationBell ? (
            <NotificationBell />
          ) : null}

          <AuthStatus />
        </Box>
      </NavWrapper>
    </>
  );
};

export default NavBar;
