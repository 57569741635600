import { useRouter } from 'next/router';
import { useTheme } from 'styled-components';

import { Box, Typography } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';

import useLocaleAndAppType from '../hooks/useLocaleAndAppType';
import { generateDownloadLink } from '../helpers';
import { DownloadAppType } from '../types';

import { DOWNLOAD_APP_DATA, DOWNLOAD_APP_TYPES } from '../constants';
import { CustomButton } from './styledComponents';

const QR_ICON_SIZE = 64;

type DownloadAppProps = {
  type?: DownloadAppType;
};

const DownloadAppBanner = ({ type }: DownloadAppProps) => {
  const theme = useTheme();
  const { isEHJobsInNonAU } = useLocaleAndAppType(type);

  const isBannerEnabled = !!type && DOWNLOAD_APP_DATA[type].enabled;

  if (!isBannerEnabled) {
    return null;
  }

  if (!type || isEHJobsInNonAU) {
    return (
      <Image
        style={{
          width: '100%',
          height: 160,
          borderRadius: theme.radii.large,
        }}
        alt="App banner placeholder"
        src="/assets/images/app-banner-placeholder.png"
      />
    );
  }

  const { title, qrCode } = DOWNLOAD_APP_DATA[type];

  return (
    <Box
      data-test-id="download-app-banner"
      sx={{
        maxWidth: 190,
        gap: 'small',
        display: 'flex',
        padding: 'medium',
        textAlign: 'center',
        borderColor: 'text',
        alignItems: 'center',
        borderStyle: 'solid',
        borderWidth: 'base',
        borderRadius: 'large',
        flexDirection: 'column',
        justifyContent: 'center',
        bgColor: 'defaultLightBackground',
      }}
    >
      <Typography.Text fontSize={16} fontWeight="semi-bold" sx={{ width: 160 }}>
        Download the{' '}
        <span style={{ color: theme.colors.primary }}>
          {type !== DOWNLOAD_APP_TYPES.EH_JOBS ? 'Work app by ' : ''}Employment
          Hero
        </span>{' '}
        {title}
      </Typography.Text>

      <Box sx={{ width: QR_ICON_SIZE, height: QR_ICON_SIZE, mt: 'small' }}>
        <Image
          src={qrCode}
          alt="DOWNLOAD APP QR Code"
          sx={{ height: 'fit-content' }}
        />
      </Box>
    </Box>
  );
};

const DownloadButton = ({ type }: DownloadAppProps) => {
  const router = useRouter();
  const { locale = '', isEHJobsInNonAU } = useLocaleAndAppType(type);

  if (!type || isEHJobsInNonAU || !DOWNLOAD_APP_DATA[type].enabled) {
    return null;
  }

  return (
    <Box sx={{ mb: 'medium', pl: 'medium', pr: 'medium' }}>
      <CustomButton
        intent="primary"
        variant="outlined"
        data-test-id="download-app-button"
        text={DOWNLOAD_APP_DATA[type].buttonText}
        onClick={() => router.push(generateDownloadLink(locale, type))}
      />
    </Box>
  );
};

export default {
  Button: DownloadButton,
  Banner: DownloadAppBanner,
};
